import { startCase, last } from 'lodash';
import { IMm3AssetType } from 'types';
import { ReportTypes } from 'types/producer-portal';

export const formatType = (type?: string, separator = '::'): string => {
  switch (type) {
    case ReportTypes.Top10:
    case ReportTypes.Top30:
    case ReportTypes.Top50:
      return startCase(type.replace('Report::', ''));

    case IMm3AssetType.IMF:
      return 'IMF/DCP';

    default:
      return startCase(last(type?.split(separator)));
  }
};
