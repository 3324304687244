import React from 'react';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import FormSectionWrapperV2 from 'components/form-section-wrapper-v2/form-section-wrapper-v2';
import { FormInput, FormMultiSelect } from 'helpers/form';

import { getTags } from 'components/product-edit-sections/utils';
import { PmGenericProduct3MotionPicture, PmLgtLayer3MotionPictureStandard } from '@mediafellows/mm3-types';

interface IProductEditSectionProps {
  layerForm: IUseMm3FormReturn<PmLgtLayer3MotionPictureStandard>;
  productForm: IUseMm3FormReturn<PmGenericProduct3MotionPicture>;
}

export const ProductEditIdentifiersSection: React.FC<IProductEditSectionProps> = ({ productForm, layerForm }) => {
  const { formData, handlers } = productForm;

  return (
    <FormSectionWrapperV2 className="create_product__form mb-3" title="Identifiers">
      <FormInput
        name="internal_reference"
        key="internal_reference"
        label="Internal Reference"
        {...formData.internal_reference}
        {...handlers}
      />
      <FormInput
        name="external_reference"
        key="external_reference"
        label="External Reference"
        {...formData.external_reference}
        {...handlers}
      />
      <FormInput
        name="meta.product_type"
        key="meta.product_type"
        label="Product Type"
        {...layerForm.formData.meta?.product_type}
        {...layerForm.handlers}
      />
      <FormInput
        name="meta.brand_name"
        key="meta.brand_name"
        label="Brand Name"
        {...layerForm.formData.meta?.brand_name}
        {...layerForm.handlers}
      />
      <FormInput
        name="meta.identifier_archive_number"
        key="meta.identifier_archive_number"
        label="DXNO"
        {...layerForm.formData.meta?.identifier_archive_number}
        {...layerForm.handlers}
      />
      <FormInput name="meta.web_page" label="Website" {...layerForm.handlers} {...layerForm.formData.meta?.web_page} />
      <FormMultiSelect
        name="meta.tags"
        label="Tags"
        {...layerForm.handlers}
        {...layerForm.formData.meta?.tags}
        fetchValues={getTags}
        allowNewItems
        hideClearIfEmptySelect
      />
      <FormInput
        name="meta.imdb_id"
        key="imdb_id"
        label="IMDb ID"
        {...layerForm.formData.meta?.imdb_id}
        {...layerForm.handlers}
        validation={{
          valid: Boolean(layerForm.formData.meta?.imdb_id?.validation?.valid),
          errorMessage: 'Must meet IMDb ID format, i.e. consist of 2 letters followed by at least 7 digits.',
        }}
      />
    </FormSectionWrapperV2>
  );
};
