import React from 'react';
import { Tooltip } from '@mantine/core';

import { FormCheckbox, IFormHandlers, IFormMm3Handlers } from 'helpers/form';
import { MantineIcon } from 'utils/ui';
import { IFormData } from 'helpers/form/types';
import { IAssetPresetFields } from 'types/asset';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<Partial<IAssetPresetFields>>;
  handlers: IFormHandlers<Partial<IAssetPresetFields>> | IFormMm3Handlers<Partial<IAssetPresetFields>>;
  values: IAssetPresetFields;
}

export const MarketingUseField: React.FC<IAssetPresetFieldsProps> = ({ formData, handlers, formId, values }) => {
  return (
    <>
      <div className="d-flex justify-content-space-between">
        <FormCheckbox
          formId={formId}
          name="product.marketing_use"
          label="Marketing Use"
          {...formData['product.marketing_use']}
          {...handlers}
          className="mb-1"
          disabled={!values.products.length}
        />
        <Tooltip label={'Will be flagged for marketing use ★ on the selected Product(s)'}>
          <div className="info-sign_container">
            <MantineIcon icon="info-sign" size={11} />
          </div>
        </Tooltip>
      </div>
    </>
  );
};
