import React from 'react';
import { Row, yn } from 'components/label-value-pair';
import { IContact, ILgtContact } from 'types';

export const CustomContactRow: React.FC<{ contact: ILgtContact }> = () => <></>;
export const CustomContactBusinessRow: React.FC<{ contact: IContact }> = () => <></>;

export const CustomContactSettingsRow: React.FC<{ contact: ILgtContact }> = ({ contact: { disable_watermark } }) => {
  return <Row label="Disable Watermark" value={yn(disable_watermark)} size="m" />;
};
