import React from 'react';
import { observer } from 'mobx-react-lite';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { Routes } from 'utils/routes';
import { Row } from 'components/label-value-pair';

import { CustomRow } from 'components/product-preview-tab/utils';

import AssetsMainClassificationLink from 'components/asset/assets-aggregation/product-assets';

import { InfoBoxV2 } from 'components/info-box-v2';
import { useDataSectionStore } from 'store';
import { IProduct, DetailsPageTabs } from 'types';
import { PmGenericLayer3MotionPictureStandard } from '@mediafellows/mm3-types';
import { formatDate } from 'utils/date';
import { formatField } from 'utils/general';
import { useLanguageVersionInfo } from 'utils/hooks';
import { CategoriesInfo } from 'utils/ui';
import { formatType } from 'utils/format-type';
import { useEntityOwnership } from 'utils/hooks/use-divisions';

const ProductPreviewAttributes: React.FC = observer(() => {
  const { activeItem: product } = useDataSectionStore<IProduct>();

  const {
    id,
    status,
    access_level,
    permissions,
    created_at,
    updated_at,
    deleted_at,
    published_at,
    type: productType,
    available_since,
  } = product || {};
  const layer = (product?.default_layer || {}) as PmGenericLayer3MotionPictureStandard;
  const {
    category_ids,
    language_versions,
    resolution,
    product_state,
    year_of_production_text,
    year_of_production,
    duration,
    duration_text,
  } = layer.meta || {};

  const languageInfo = useLanguageVersionInfo(language_versions);
  const isDeletedProduct = status === 'deleted';
  const { ownershipInfo, loadingOwnership } = useEntityOwnership(product);

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 1fr" className="product-preview__infobox px-3">
      <Row
        label="Assets"
        valueClassName="overflow-inherit"
        value={
          <AssetsMainClassificationLink
            assets={product?.assets}
            baseUrl={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.ASSETS}`}
            muted={false}
          />
        }
      />

      <Row
        hideIfNoValue
        label="Access"
        value={
          <>
            {access_level} <ScheduledChangeIndicator entity={product} />
          </>
        }
      />
      <Row hideIfNoValue label="Status" value={status} />
      <Row hideIfNoValue label="Permissions" value={permissions?.join(', ')} />
      <Row label="Ownership" value={ownershipInfo} loading={loadingOwnership} />
      <Row hideIfNoValue rawLabel label="Created at" value={formatDate(created_at)} />
      <Row hideIfNoValue rawLabel label="Updated at" value={formatDate(updated_at)} />
      <Row hideIfNoValue rawLabel label="Published at" value={formatDate(published_at)} />
      <Row hideIfNoValue rawLabel label="Available since" value={formatDate(available_since)} />
      {isDeletedProduct && <Row rawLabel hideIfNoValue label="Deleted at" value={formatDate(deleted_at)} />}
      <Row hideIfNoValue label="Type" value={formatType(productType)} />
      <Row hideIfNoValue rawLabel label="Product status" value={product_state} />
      <Row hideIfNoValue rawLabel label="Year of production" value={year_of_production_text || year_of_production} />
      <Row hideIfNoValue className="text-lowercase" label="Duration" value={duration_text || duration} />
      <Row hideIfNoValue label="Resolution" value={formatField(resolution)} />
      <Row hideIfNoValue rawLabel label="Genres" value={<CategoriesInfo category_ids={category_ids} />} />
      <Row hideIfNoValue rawLabel label="Language" value={languageInfo} />
      <CustomRow product={product as IProduct} />
    </InfoBoxV2>
  );
});

export default ProductPreviewAttributes;
