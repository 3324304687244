import { UppyFile } from '@uppy/core/types';

import { getIsMm3Assets } from 'utils/asset';
import { getAssetType } from 'utils/format-asset-type';

import { IAssetFile } from 'types';

export function formatFileFields({ preview, id, type: typeProp, name, ...fileData }: UppyFile): IAssetFile {
  const isMm3Assets = getIsMm3Assets();

  const type = isMm3Assets ? getAssetType(typeProp, fileData?.extension) : typeProp;

  return {
    access_level: 'company',
    file_name: name,
    fileData,
    id,
    languages: [],
    name,
    permissions: [],
    preview,
    ...(isMm3Assets ? {} : { protection_levels: [] }),
    selected: true,
    status: 'available',
    watermark: '',
    type: type || '',
    classification: '',
    products: [],
    'layer.notes': '',
    sharable: true,
    'meta.purpose': undefined,
    'meta.language_id': undefined,
    'meta.ai_generated': undefined,
    'meta.ai_notes': '',
    'product.marketing_use': false,
    division_ids: [],
  };
}
